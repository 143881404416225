function isEmpty(value) {
  return value.length === 0;
}

function isNullOrEmpty(value) {
  if (!value) {
    return true;
  }
  return value.trim() === '';
}
const valid = {
  REG_PHONE: /^[1]([3-9])[0-9]{9}$/,
  REG_TAX_NO: /^[\dA-Z]{15,20}$/,
  REG_COMPANY: /^[\u4E00-\u9FA5\da-zA-Z_&]{2,50}$/,
  REG_DEVICE_CREW: /^[\u4E00-\u9FA5\da-zA-Z0-9_-]{2,10}$/,
  checkNull(rule, value, callback, message, flag = true) {
    if (isNullOrEmpty(value)) {
      callback(new Error(message));
    }
    if (flag) {
      callback();
    }
  },
  checkSelect(rule, value, callback, message, flag = true) {
    if (isNullOrEmpty(value)) {
      callback(new Error(message));
    }
    if (flag) {
      callback();
    }
  },
  checkMultiSelect(rule, value, callback, message, flag = true) {
    if (isEmpty(value)) {
      callback(new Error(message));
    }
    if (flag) {
      callback();
    }
  },
  // 判断字数
  checkByRegex(rule, value, callback, regex, message, flag = true) {
    if (!regex.test(value)) {
      callback(new Error(message));
    }
    if (flag) {
      callback();
    }
  },
  checkComment(rule, value, callback, count, message) {
    if (value.length > count) {
      callback(new Error(message));
    } else {
      callback();
    }
  },
  checkBybite(rule, value, callback, message) {
    let len = 0;
    for (let i = 0; i < value.length; i += 1) {
      const c = value.charCodeAt(i);
      // 单字节加1
      if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
        len += 1;
      } else {
        len += 2;
      }
    }
    if (len > 32 || len < 4) {
      callback(new Error(message));
    } else {
      callback();
    }
  },
  // 判断字节个数： max最大字节数、min最小字节数
  checkBytes(rule, value, callback, min, max, message) {
    let len = 0;
    for (let i = 0; i < value.length; i += 1) {
      const c = value.charCodeAt(i);
      // 单字节加1
      if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
        len += 1;
      } else {
        len += 2;
      }
    }
    if (len > max || len < min) {
      callback(new Error(message));
    } else {
      callback();
    }
  },
  isNullOrEmpty(value) {
    if (!value) {
      return true;
    }
    return value.trim() === '';
  },
};
export default valid;
